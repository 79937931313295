<template>
  <b-modal
    id="modal_nuevo_usuario"
    ok-only
    size="lg"
    :header-bg-variant="cabezeraModal"
    hide-footer
  >
    <template
      #modal-header
    >
      <div class="text-white">
        <strong class="float-left">
          {{ nombreModal }}
        </strong></div>

    </template>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>

          <b-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="usuarioDni"
              rules="required"
            >
              <b-form-group
                label="Documento de identidad"
                label-for="usuarioDni"
              >
                <b-form-input
                  id="usuarioDni"
                  v-model="usuario.dni"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  El campo DNI es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
          >
            <b-form-group
              label="Estado"
              label-for=""
            >
              <b-form-radio-group
                v-model="usuario.activo"
                :options="options"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              rules="required"
            >
              <b-form-group
                label="Rol(es)"
                label-for="servicios-select"
                :state="getValidationState(validationContext)"
              >
                <!-- <label for="">Requisitos:</label> -->
                <b-row
                  class="rounded py-1"
                  style="margin: 0px 2px;"
                  :class="validationContext.errors[0] ? 'border-danger' : 'border'"
                >
                  <b-col
                    cols="12"
                    class="text-center"
                  >

                    <b-form-checkbox
                      v-for="option in rolesOptions"
                      :key="option.id"
                      v-model="rolesSelected"
                      :value="option.name"
                      name="flavour-3a"
                      inline
                      class="mx-2 mb-1"
                      :disabled="option.name != 'Prof. OCADE' && !$can('JEFE-OCADE', 'api') "
                    >
                      {{ option.name }}

                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  <small
                    v-if="validationContext.errors[0]"
                    class=""
                  >
                    Seleccionar 1 rol como mínimo
                  </small>

                </b-form-invalid-feedback>

              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="usuarioApellidos"
              rules="required"
            >
              <b-form-group
                label="Apellidos"
                label-for="usuarioApellidos"
              >
                <b-form-input
                  id="usuarioApellidos"
                  v-model="usuario.apellidos"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  El campo APELLIDOS es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="usuarioNombres"
              rules="required"
            >
              <b-form-group
                label="Nombres"
                label-for="usuarioNombres"
              >
                <b-form-input
                  id="usuarioNombres"
                  v-model="usuario.nombres"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  El campo NOMBRES es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="usuarioEmail"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="usuarioEmail"
              >
                <b-form-input
                  id="usuarioEmail"
                  v-model="usuario.email"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  El campo EMAIL es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            xl="6"
            lg="6"
            md="6"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="usuarioCargoFuncion"
              rules="required"
            >
              <b-form-group
                label="Cargo o Función"
                label-for="usuarioCargoFuncion"
              >
                <b-form-input
                  id="usuarioCargoFuncion"
                  v-model="usuario.cargo_funcion"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  El campo CARGO o FUNCIÓN es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            class="my-1"
          >
            <b-form-checkbox
              switch
              :value="true"
              :unchecked-value="false"
              @change="()=>verDetalles = !verDetalles"
            >
              <span class="text-primary">Detalles opcionales</span>
            </b-form-checkbox>
          </b-col>

          <b-col
            v-show="verDetalles"
            cols="12"
          >
            <b-row>
              <b-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
              >
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Carrera/Profesión"
                      label-for="usuarioCarreraProfesion"
                    >
                      <b-form-input
                        id="usuarioCarreraProfesion"
                        v-model="usuario.carrera_profesion"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col

                    sm="12"
                  >
                    <b-form-group
                      label="Especialidad"
                      label-for="usuarioEspecialidad"
                    >
                      <b-form-input
                        id="usuarioEspecialidad"
                        v-model="usuario.especialidad"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    sm="12"
                  >
                    <b-form-group
                      label="Tratamiento (Ejemplo: Dr.)"
                      label-for="usuarioTratamiento"
                    >
                      <b-form-input
                        id="usuarioTratamiento"
                        v-model="usuario.tratamiento"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                  >
                    <b-form-group
                      label="Grado académico"
                      label-for="usuarioGradoacademico"
                    >
                      <b-form-input
                        id="usuarioGradoacademico"
                        v-model="usuario.grado_academico"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    sm="12"
                  >
                    <b-form-group
                      label="Código Colegiatura"
                      label-for="usuarioColegiatura"
                    >
                      <b-form-input
                        id="usuarioColegiatura"
                        v-model="usuario.cod_colegiatura"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-col>

              <b-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="border-light-warning rounded"
              >
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Fotografia"
                      label-for="usuarioFotografia"
                    >
                      <b-img
                        thumbnail
                        center
                        fluid
                        :src="usuario.foto ? usuario.foto : require('@/assets/images/user/foto_usuario_default.jpg')"
                        alt="Image 1"
                        width="120"
                        style="margin-bottom:5px;"
                      />
                      <b-form-file
                        id="usuarioFotografia"
                        v-model="fotografiaUsuario"
                        size="sm"
                        accept=".jpg, .png, .jpeg, .gif, .svg"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mt-1"
                  >
                    <b-form-group
                      label="Firma"
                      label-for="usuarioFirma"
                    >
                      <b-img
                        v-if="usuario.firma"
                        thumbnail
                        center
                        fluid
                        :src="usuario.firma"
                        alt="Image 1"
                        width="120"
                        style="margin-bottom:5px;"
                      />
                      <h4
                        v-else
                        class="text-center text-danger"
                      >
                        NO EXISTE FIRMA
                      </h4>
                      <b-form-file
                        id="usuarioFirma"
                        v-model="firmaUsuario"
                        size="sm"
                        accept=".jpg, .png, .jpeg, .gif, .svg"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-col>

            </b-row>
          </b-col>
        </b-row>
        <!-- Form Actions -->
        <div class="d-flex justify-content-between mt-2 border-top pt-1">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
            size="sm"
            @click="$bvModal.hide('modal_nuevo_usuario')"
          >
            <feather-icon
              icon="XIcon"
              size="14"
            />
            Cancelar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="`outline-${cabezeraModal}`"
            class="mr-2"
            type="submit"
          >
            {{ nombreBotonModal }}
            <feather-icon
              :icon="vamosCrear ? 'SaveIcon' : 'RefreshCcwIcon'"
              size="14"
            />
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import store from '@/store'
import {
  BButton, BModal, BForm, BFormGroup, BFormInvalidFeedback, BCol, BRow, BFormInput,
  BFormFile, BFormCheckbox, BFormRadioGroup, BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import vSelect from 'vue-select'

export default {
  components: {
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormFile,
    BButton,
    BCol,
    BRow,
    BModal,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    BImg,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      fotografiaUsuario: null,
      firmaUsuario: null,
      verDetalles: false,
      usuario: {
        password: 'password',
      },
      options: [
        { item: 1, name: 'Activo' },
        { item: 0, name: 'Inactivo' },

      ],

      avatarText,
      required,
      vamosCrear: true,
      nombreModal: '',
      nombreBotonModal: '',
      cabezeraModal: '',
      optionRoles: [],
      rolesUsuario: [],
      dir: 'ltr',

      rolesOptions: {},
      rolesSelected: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async created() {
    this.$parent.$on('editarDatosProfesor', this.editarDatosProfesor)
    this.$parent.$on('nuevoProfesor', this.nuevoProfesor)

    await store.dispatch('profesor-store-module/getRolesOcade')
      .then(res => {
        this.rolesOptions = res.data
      })
  },
  methods: {
    nuevoProfesor() {
      this.nombreModal = 'Nuevo usuario'
      this.nombreBotonModal = 'Grabar'
      this.cabezeraModal = 'primary'
      this.vamosCrear = true
      this.limpiarFormulario()
      this.usuario.activo = 1
      this.rolesSelected = []
      this.$bvModal.show('modal_nuevo_usuario')
    },
    editarDatosProfesor(data) {
      this.rolesSelected = []
      this.rolesSelected = data.roles.map(val => val.name)

      this.nombreModal = 'Actualizar Usuario'
      this.nombreBotonModal = 'Actualizar'
      this.cabezeraModal = 'warning'
      this.vamosCrear = false
      this.limpiarFormulario()
      this.usuario = JSON.parse(JSON.stringify(data))
      delete this.usuario.created_at
      delete this.usuario.updated_at
      delete this.usuario.updated_at
      delete this.usuario.roles
      delete this.usuario.permissions
      this.$bvModal.show('modal_nuevo_usuario')
    },
    onSubmit() {
      if (this.vamosCrear) {
        this.createProfesor()
      } else {
        this.updateProfesor()
      }
    },
    createProfesor() {
      const FORM_DATA = new FormData()
      Object.entries(this.usuario).forEach(([key, value]) => {
        if (key !== 'foto' && key !== 'firma') {
          if (value !== null) {
            FORM_DATA.set(key, value)
          }
        }
      })
      if (this.fotografiaUsuario) {
        FORM_DATA.set('foto', this.fotografiaUsuario)
      }
      if (this.firmaUsuario) {
        FORM_DATA.set('firma', this.firmaUsuario)
      }
      this.rolesSelected.forEach((val, index) => {
        FORM_DATA.set(`roles[${index}]`, val)
      })

      store.dispatch('profesor-store-module/addProfesor', FORM_DATA)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Usuario registrado!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('actualizarTablaProfesores')
          this.$bvModal.hide('modal_nuevo_usuario')
        }).catch(error => {
          if (error.response.data.error !== undefined) {
            Object.entries(error.response.data.error).forEach(([key, value]) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${key}: ${value[0]}`,
                  icon: 'CheckCircleIcon',
                  variant: 'warning',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error en crear',
                icon: 'CheckCircleIcon',
                variant: 'warning',
              },
            })
          }
        })
    },
    updateProfesor() {
      const FORM_DATA = new FormData()
      Object.entries(this.usuario).forEach(([key, value]) => {
        if (key !== 'foto' && key !== 'firma') {
          if (value !== null) {
            FORM_DATA.set(key, value)
          }
        }
      })

      if (this.fotografiaUsuario) {
        FORM_DATA.set('foto', this.fotografiaUsuario)
      }
      if (this.firmaUsuario) {
        FORM_DATA.set('firma', this.firmaUsuario)
      }
      this.rolesSelected.forEach((val, index) => {
        FORM_DATA.set(`roles[${index}]`, val)
      })
      FORM_DATA.set('_method', 'put')

      const data = {
        id: this.usuario.id,
        data: FORM_DATA,
      }

      store.dispatch('profesor-store-module/updateProfesor', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Usuario actualizado!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('actualizarTablaProfesores')
          this.$bvModal.hide('modal_nuevo_usuario')
        }).catch(error => {
          if (error.response.data.code === 422) {
            // Object.entries(error.response.data.error).forEach(([key, value]) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: '¡Advertencia!',
                text: `${error.response.data.error}`,
                icon: 'CheckCircleIcon',
                variant: 'warning',
              },
            })
            // })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: '¡Error en en actualizar!',
                icon: 'CheckCircleIcon',
                variant: 'warning',
              },
            })
          }
        })
    },

    limpiarFormulario() {
      this.usuario = {}
      this.fotografiaUsuario = null
      this.firmaUsuario = null
    },

  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>
