<template>
  <b-modal
    id="modal_reestablecer_password_profesor"
    ok-only
    size="md"
    header-bg-variant="primary"
    hide-footer
  >
    <template
      #modal-header
    >
      <div class="text-white">
        <strong class="float-left">
          Restablecer contraseña
        </strong></div>

    </template>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>
          <b-col
            xl="12"
            lg="12"
            md="12"
            sm="12"
          >
            <validation-provider
              #default="validationContext"
              name="nuevoPassword"
              rules="required"
            >
              <b-form-group
                label="Nueva contraseña"
                label-for="nuevoPassword"
              >
                <b-form-input
                  id="nuevoPassword"
                  v-model="nuevoPassword"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  El campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
        <!-- Form Actions -->
        <div class="d-flex justify-content-between mt-2 border-top pt-1">
          <b-button
            type="button"
            variant="outline-danger"
            size="sm"
            @click="$bvModal.hide('modal_reestablecer_password_profesor')"
          >
            <feather-icon
              icon="XIcon"
              size="14"
            />
            Cancelar
          </b-button>
          <b-button
            :variant="`outline-success`"
            class="mr-2"
            type="submit"
          >
            Restablecer contraseña
            <feather-icon
              icon="RefreshCcwIcon"
              size="14"
            />
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import store from '@/store'
import {
  BButton, BModal, BForm, BFormGroup, BFormInvalidFeedback, BCol, BRow, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import vSelect from 'vue-select'

export default {
  components: {
    // vSelect,
    // BInputGroup,
    // BInputGroupAppend,
    BFormInput,
    BButton,
    BCol,
    BRow,
    BModal,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      nuevoPassword: '',
      usuario: {},
      required,
      dir: 'ltr',

    }
  },

  mounted() {
    // store.dispatch('profesor-store-module/getRoles').then(res => {
    //   this.optionRoles = res.data.map(val => ({ label: val.name, value: val.id }))
    // })
  },
  created() {
    this.$parent.$on('reestablecerPassword', this.reestablecerPassword)
  },
  methods: {
    reestablecerPassword(data) {
      this.limpiarFormulario()
      this.nuevoPassword = ''
      this.usuario = JSON.parse(JSON.stringify(data))
      delete this.usuario.foto
      delete this.usuario.firma
      delete this.usuario.created_at
      delete this.usuario.updated_at
      delete this.usuario.updated_at
      delete this.usuario.roles
      delete this.usuario.permissions
      // console.log('data USUARIO:', data)
      this.$bvModal.show('modal_reestablecer_password_profesor')
    },
    onSubmit() {
      this.updateProfesor()
    },

    updateProfesor() {
      const FORM_DATA = new FormData()
      FORM_DATA.set('password', this.nuevoPassword)
      Object.entries(this.usuario).forEach(([key, value]) => {
        FORM_DATA.set(key, value)
      })

      FORM_DATA.set('_method', 'put')

      const data = {
        id: this.usuario.id,
        data: FORM_DATA,
      }

      store.dispatch('profesor-store-module/updateProfesor', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Contraseña reestablecida!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          // this.$emit('actualizarTablaProfesores')
          this.$bvModal.hide('modal_reestablecer_password_profesor')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Error en en actualizar!',
              icon: 'CheckCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    limpiarFormulario() {
      this.usuario = {}
      this.fotografiaUsuario = null
      this.firmaUsuario = null
      // this.estudianteSelect = {}
    },

  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>
