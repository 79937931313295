<template>
  <div>
    <modal-profesor-nuevo @actualizarTablaProfesores="actualizarTablaProfesores" />
    <modal-reestablecer-password />
    <b-card class="mt-0 px-2">

      <b-table
        id="my-table"
        ref="refTablaProfesoresList"
        class="position-relative"
        show-empty
        head-variant="dark"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :no-provider-filtering="true"
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        caption-top
      >
        <template #table-caption>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
            >
              <span class="text-dark"><strong>LISTA DE PROFESORES</strong> </span>
            </b-col>
            <b-col
              xl="8"
              lg="8"
              md="8"
              sm="12"
              class="d-flex justify-content-end"
            >
              <div class="mr-1">
                <label>Mostrar</label>
                <b-form-select
                  v-model="perPage"
                  :options="pageOptions"
                  :clearable="false"
                  size="md"
                  style="width:auto"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>registros</label>
              </div>
              <div class="mr-1">
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Buscar..."
                  size="md"
                />
              </div>
              <div>
                <b-button
                  variant="primary"
                  @click="agregarNuevoProfesor"
                >
                  Nuevo profesor DIBU
                </b-button>
              </div>
            </b-col>

          </b-row>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>
        <template #cell(index)="row">
          {{ from + row.index }}
        </template>

        <template #cell(apellidos_nombres)="row">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                :src="row.item.foto"
                size="32"
                :variant="`light-primary`"
                :text="avatarText(recorteName(row.item.apellidos))"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ row.item.apellidos }} {{ row.item.nombres }}
            </b-link>
            <small><strong>DNI: </strong>{{ row.item.dni }}</small> <br>
            <small><strong>CARGO: </strong>{{ row.item.cargo_funcion }}</small>
          </b-media>
        </template>

        <template #cell(roles)="row">
          <div
            v-for="(rol,index) in row.item.roles"
            :key="index"
          >
            <b-badge
              variant="light-primary border-primary"
              style="margin-bottom: 4px;"
            >
              {{ rol.name }}
            </b-badge>
          </div>
        </template>

        <template #cell(estado)="row">

          <b-badge :variant="row.item.activo === 1 ? 'light-success' : 'light-danger'">
            {{ row.item.activo === 1 ? 'activo' : 'inactivo' }}
          </b-badge>

        </template>
        <!-- column: acciones -->
        <template #cell(acciones)="row">
          <div v-if="!row.item.roles.some(val => (val.name === 'OCADE-CULTURAL' || val.name === 'OCADE-DEPORTIVO' || val.name === 'OCADE-ALT-COMP'))">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="align-middle text-primary"
                />
              </template>

              <b-dropdown-item
                @click="editarProfesor(row.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="restablecerContrasenia(row.item)"
              >
                <feather-icon icon="KeyIcon" />
                <span class="align-middle ml-50">Reestablecer contraseña</span>
              </b-dropdown-item>

            </b-dropdown>
          </div>
          <div
            v-else-if="$can('JEFE-OCADE', 'api')"
          >
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="align-middle text-primary"
                />
              </template>

              <b-dropdown-item
                @click="editarProfesor(row.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="restablecerContrasenia(row.item)"
              >
                <feather-icon icon="KeyIcon" />
                <span class="align-middle ml-50">Reestablecer contraseña</span>
              </b-dropdown-item>

            </b-dropdown>

          </div>
          <div
            v-else
            class="text-center"
          >
            <feather-icon
              icon="ShieldOffIcon"
              size="18"
              class="align-middle text-danger"
            />
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BPagination, BFormInput, BDropdown,
  BDropdownItem, BButton, BMedia, BAvatar, BLink, BFormSelect, BBadge,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import ModalReestablecerPassword from './ModalReestablecerPassword.vue'
import ModalProfesorNuevo from './ModalProfesorNuevo.vue'

import profesorStoreModule from './profesorStoreModule'

export default {
  components: {
    BBadge,
    BCard,
    BTable,
    BSpinner,
    BFormSelect,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BMedia,
    BAvatar,
    BLink,

    ModalProfesorNuevo,
    ModalReestablecerPassword,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 30, 50, 200, 500],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],

    }
  },
  methods: {
    actualizarTablaProfesores() {
      this.filter = null
      this.$refs.refTablaProfesoresList.refresh()
    },
    agregarNuevoProfesor() {
      this.$emit('nuevoProfesor')
    },
    editarProfesor(item) {
      this.$emit('editarDatosProfesor', item)
    },
    restablecerContrasenia(item) {
      this.$emit('reestablecerPassword', item)
    },

    async myProvider(ctx) {
      const promise = store.dispatch('profesor-store-module/getProfesores', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        this.totalRows = total || 0
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },

    recorteName(name) {
      let recorte = []
      recorte = name.split(' ')
      return `${recorte[0]} ${recorte[1]}`
    },
  },
  setup() {
    const PROFESOR_APP_STORE_MODULE_NAME = 'profesor-store-module'

    // Register module
    if (!store.hasModule(PROFESOR_APP_STORE_MODULE_NAME)) store.registerModule(PROFESOR_APP_STORE_MODULE_NAME, profesorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFESOR_APP_STORE_MODULE_NAME)) store.unregisterModule(PROFESOR_APP_STORE_MODULE_NAME)
    })

    const fields = [
      { key: 'index', label: 'N°', sortable: true },
      { key: 'apellidos_nombres', label: 'APELLIDOS Y NOMBRES', sortable: true },
      { key: 'usuario', label: 'USUARIO', sortable: true },
      { key: 'roles', label: 'ROLES', sortable: true },
      { key: 'estado', label: 'ESTADO', sortable: true },
      { key: 'acciones' },
    ]
    return {
      // Filter
      avatarText,
      fields,
      profesorStoreModule,
    }
  },
}
</script>
<style lang="scss">
</style>
