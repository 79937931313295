import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getProfesores(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-cultural-deportivo/profesor', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProfesor(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-cultural-deportivo/profesor', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProfesor(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-cultural-deportivo/profesor/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRolesOcade() {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-cultural-deportivo/recursos/show-roles', { params: { sin_paginar: true } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
